import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Modal,
  Pagination,
  CircularProgress,
  Tooltip,
  Slide,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import EmailIcon from "@mui/icons-material/Email";
import style from "./styles/financialOverlay";
import { baseUrl } from "../../config";
import axiosConfig from "../../utils/axiosConfig";
import { columnNameKeys, defaultRowCount, paginationNames } from "./constants";
import OverlayTableSection from "./components/OverlayTableComponent";
import {
  OverlayResponseData,
  ProjectData,
  InvoiceDetailsData,
  SalaryDetailsData,
  OtherExpenseDetailsData,
  ShowLoader,
  CurrentPage,
  CardData,
  ShowDetails,
} from "./types";

const OverlayComponent = React.memo(
  ({
    data,
    isOpen,
    handleClose,
    dashboardId,
  }: {
    data: CardData;
    isOpen: boolean;
    handleClose: () => void;
    dashboardId: number;
  }) => {
    const [details, setDetails] = useState<OverlayResponseData>({});
    const [projects, setProjects] = useState<ProjectData[]>([]);
    const [invoiceDetailsData, setInvoiceDetailsData] =
      useState<InvoiceDetailsData>({});
    const [salaryDetails, setSalaryDetails] = useState<SalaryDetailsData>({});
    const [otherExpenseDetails, setOtherExpenseDetails] =
      useState<OtherExpenseDetailsData>({});
    const [showLoader, setShowLoader] = useState<ShowLoader>({
      invoice: true,
      salary_expense: true,
      other_expense: true,
      projects: true,
      initialData: true,
    });
    const [currentPage, setCurrentPage] = useState<CurrentPage>({
      invoice: 1,
      salary_expense: 1,
      other_expense: 1,
      project: 1,
    });
    const [isShowDetails, setIsShowDetails] = useState<ShowDetails>({
      invoice: false,
      salary_expense: false,
      other_expense: false,
    });

    const tableData = {
      invoice: {
        name: "Invoice",
        totalHeading: "Total Invoiced Amount:",
        tableHeading1: "Invoice Number",
        tableHeading2: "Invoice Date",
        tableHeading3: "Amount",
      },
      salaryExpense: {
        name: "Salary Expense",
        totalHeading: "Total Salary Expense/Talent:",
        tableHeading1: "Name",
        tableHeading2: "Hours",
        tableHeading3: "Amount",
      },
      otherExpense: {
        name: "Other Expense",
        totalHeading: "Total Other Expenses:",
        tableHeading1: "Expense Detail",
        tableHeading2: "Date",
        tableHeading3: "Amount",
      },
    };
    const [openModal, setOpenModal] = useState<boolean>(isOpen);
    useEffect(() => {
      const fetchData = async () => {
        try {
          if (![25, 30].includes(dashboardId)) {
            return null;
          }
          let response = await axiosConfig({
            method: "get",
            url: `${baseUrl}/${
              dashboardId === 25
                ? "financial-overview"
                : "financial-overview-grade-average"
            }/customer-overview?dashboard_id=${dashboardId}&year=${
              data.year ? data.year : ""
            }&customer_id=${data.customerId}&month=${data.monthIndex}`,
          });
          if (response.status === 200 && response.data.data) {
            const responseData = response.data.data;
            setDetails(responseData);
            setProjects(responseData.projects);
          }
          setShowLoader((prevState) => ({
            ...prevState,
            projects: false,
            initialData: false,
          }));
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      if (
        data.customerId &&
        data.year &&
        data.month &&
        data.monthIndex &&
        dashboardId
      ) {
        fetchData();
        fetchProjectsData(1);
      }
    }, [data.customerId, data.year, data.month, data.monthIndex, dashboardId]);

    useEffect(() => {
      switch (data.columnNameKey) {
        case columnNameKeys.otherExpense:
          handlePageChange(1, paginationNames.otherExpense);
          break;
        case columnNameKeys.revenue:
          handlePageChange(1, paginationNames.invoice);
          break;
        case columnNameKeys.salaryExpense:
          handlePageChange(1, paginationNames.salaryExpense);
          break;
        default: {
          console.warn("Unknown page name:", data.columnNameKey);
          break;
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.columnNameKey]);

    useEffect(() => {
      const showDetailData = {
        invoice: false,
        salary_expense: false,
        other_expense: false,
      };
      switch (data.columnNameKey) {
        case columnNameKeys.otherExpense:
          showDetailData.other_expense =
            checkNullValue(details.other_expense?.amount) !== "NA";
          break;
        case columnNameKeys.revenue:
          showDetailData.invoice =
            checkNullValue(details.revenue?.amount) !== "NA";
          break;
        case columnNameKeys.salaryExpense:
          showDetailData.salary_expense =
            checkNullValue(details.salary_expense?.amount) !== "NA";
          break;
        default: {
          break;
        }
      }
      setIsShowDetails(showDetailData);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      data.columnNameKey,
      details.other_expense?.amount,
      details.salary_expense?.amount,
      details.revenue?.amount,
      data.key,
    ]);

    const handlePageChange = (newPage: number, pageName: string) => {
      setCurrentPage((prevState) => ({
        ...prevState,
        [pageName]: newPage,
      }));
      switch (pageName) {
        case paginationNames.project:
          fetchProjectsData(newPage);
          break;
        case paginationNames.invoice:
          fetchInvoices(newPage);
          break;
        case paginationNames.otherExpense:
          fetchOtherExpenseData(newPage);
          break;
        case paginationNames.salaryExpense:
          fetchSalaryExpenseData(newPage);
          break;
        default: {
          console.warn("Unknown page name:", pageName);
          break;
        }
      }
    };

    const convertedDetails = {
      invoice:
        invoiceDetailsData?.invoice_details &&
        invoiceDetailsData?.invoice_details.map((inv) => ({
          column1: inv.invoice_number,
          column2: inv.invoice_date,
          column3: inv.amount.toLocaleString("en-IN"),
        })),
      salary_expense:
        salaryDetails.salary_expense &&
        salaryDetails.salary_expense.map((inv) => ({
          column1: inv.employee_name,
          column2: inv.hours_worked.toString(),
          column3: inv.amount.toLocaleString("en-IN"),
        })),
      other_expense:
        otherExpenseDetails.other_expense &&
        otherExpenseDetails.other_expense.map((inv) => ({
          column1: inv.expense_detail,
          column2: inv.expense_date,
          column3: inv.amount.toLocaleString("en-IN"),
        })),
    };

    const fetchSalaryExpenseData = async (page: number) => {
      setShowLoader((prevState) => ({
        ...prevState,
        salary_expense: true,
      }));
      try {
        if (![25, 30].includes(dashboardId)) {
          return null;
        }
        let response = await axiosConfig({
          method: "get",
          url: `${baseUrl}/${
            dashboardId === 25
              ? "financial-overview"
              : "financial-overview-grade-average"
          }/customer-overview/salary-expenses?dashboard_id=${dashboardId}&year=${
            data.year ? data.year : ""
          }&customer_id=${data.customerId}&month=${data.monthIndex}&page_size=${
            defaultRowCount.project
          }&page=${page}`,
        });
        if (response.status === 200 && response.data.data) {
          setSalaryDetails({
            count: response.data.count,
            salary_expense: response.data.data,
          });
        }
        setShowLoader((prevState) => ({
          ...prevState,
          salary_expense: false,
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
        setShowLoader((prevState) => ({
          ...prevState,
          salary_expense: false,
        }));
      }
    };

    const fetchOtherExpenseData = async (currentPage: number) => {
      setShowLoader((prevState) => ({
        ...prevState,
        other_expense: true,
      }));
      if (![25, 30].includes(dashboardId)) {
        return null;
      }
      axiosConfig({
        method: "get",
        url: `${baseUrl}/financial-overview/customer-overview/other-expenses?dashboard_id=${dashboardId}&page_size=${
          defaultRowCount.other_expense
        }&page=${currentPage}&year=${data.year ? data.year : ""}&customer_id=${
          data.customerId
        }&month=${data.monthIndex}`,
      })
        .then((response) => {
          setShowLoader((prevState) => ({
            ...prevState,
            other_expense: false,
          }));
          if (response.status === 200 && response.data.data) {
            setOtherExpenseDetails({
              count: response.data.count,
              other_expense: response.data.data,
            });
          }
        })
        .catch((error) => {
          setShowLoader((prevState) => ({
            ...prevState,
            other_expense: false,
          }));
          console.error("Error fetching data:", error);
        });
    };

    const fetchProjectsData = async (page: number) => {
      setShowLoader((prevState) => ({
        ...prevState,
        projects: true,
      }));
      try {
        if (![25, 30].includes(dashboardId)) {
          return null;
        }
        let response = await axiosConfig({
          method: "get",
          url: `${baseUrl}/financial-overview/customer-overview/projects?dashboard_id=${dashboardId}&year=${
            data.year ? data.year : ""
          }&customer_id=${data.customerId}&month=${data.monthIndex}&page_size=${
            defaultRowCount.project
          }&page=${page}`,
        });
        const responseData = response.data.data;
        setProjects(responseData);
        setShowLoader((prevState) => ({
          ...prevState,
          projects: false,
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchInvoices = async (page: number) => {
      setShowLoader((prevState) => ({
        ...prevState,
        invoice: true,
      }));
      try {
        if (![25, 30].includes(dashboardId)) {
          return null;
        }
        let response = await axiosConfig({
          method: "get",
          url: `${baseUrl}/financial-overview/customer-overview/invoices?dashboard_id=${dashboardId}&year=${
            data.year ? data.year : ""
          }&customer_id=${data.customerId}&month=${data.monthIndex}&page_size=${
            defaultRowCount.project
          }&page=${page}`,
        });
        if (response.status === 200 && response.data.data) {
          setInvoiceDetailsData({
            count: response.data.count,
            invoice_details: response.data.data,
          });
        }
        setShowLoader((prevState) => ({
          ...prevState,
          invoice: false,
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
        setShowLoader((prevState) => ({
          ...prevState,
          invoice: false,
        }));
      }
    };

    const checkNullValue = (value: any, emptyValue: string = "NA") => {
      return value !== null && value !== undefined ? value : emptyValue;
    };

    const getPaginationCount = (
      totalCount: number | undefined,
      countPerPage: number
    ) => {
      return totalCount ? Math.ceil(totalCount / countPerPage) : 1;
    };

    return (
      <Modal
        open={isOpen}
        onClose={() => {
          handleClose();
          setOpenModal(false);
        }}
        onClick={(e) => e.stopPropagation()}
        sx={{
          "&.MuiBox-root": {
            outline: "none !important",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0)",
            width: "575px",
            left: "initial",
          },
          "&.MuiModal-root": {
            width: "575px",
            left: "initial",
          },
        }}
      >
        <Slide
          direction="left"
          in={openModal}
          mountOnEnter
          unmountOnExit
          timeout={{ enter: 300, exit: 300 }}
          easing={{ enter: "ease-in", exit: "ease-out" }}
        >
          <Box sx={style.modal}>
            <Box sx={style.container}>
              <Box sx={style.header}>
                <Box>
                  <Typography
                    color="text.primary"
                    sx={style.header.accountName}
                  >
                    {data.customer}
                  </Typography>
                  <Typography color="text.primary" sx={style.header.month}>
                    {data.month}, {data.year}
                  </Typography>
                </Box>
                <IconButton
                  sx={style.header.arrow}
                  color="primary"
                  aria-label="right arrow"
                  onClick={() => {
                    handleClose();
                    setOpenModal(false);
                  }}
                >
                  <ArrowForwardIcon />
                </IconButton>
              </Box>
              {!showLoader.initialData && (
                <Box
                  sx={{
                    ...style.managerDetails.container,
                    ...(details?.customer_details?.account_manager ||
                    details?.customer_details?.email ||
                    details?.customer_details?.since ||
                    details?.customer_details?.type
                      ? {}
                      : style.managerDetails.container.noData),
                  }}
                >
                  <Box
                    sx={{ ...style.managerDetails.row, marginBottom: "4px" }}
                  >
                    {details?.customer_details?.account_manager && (
                      <Box sx={style.flexRow}>
                        <Typography
                          color="text.primary"
                          sx={[style.detailTitle, style.maxSpace]}
                        >
                          Account Manager:
                        </Typography>
                        <Typography
                          color="text.primary"
                          sx={{
                            ...style.detailText,
                            marginRight: "12px",
                            ...style.fillSpace,
                          }}
                        >
                          {details?.customer_details?.account_manager}
                        </Typography>
                      </Box>
                    )}
                    {details.customer_details?.email && (
                      <Box sx={style.flexRow}>
                        <Box aria-label="email">
                          <EmailIcon
                            sx={{
                              width: "12px",
                              height: "10px",
                              color: "#76A4FD",
                              marginRight: "3px",
                            }}
                          />
                        </Box>
                        <Typography color="text.primary" sx={style.detailText}>
                          {details.customer_details?.email}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box sx={style.managerDetails.row}>
                    {details.customer_details?.since && (
                      <Box sx={style.flexRow}>
                        <Typography color="text.primary" sx={style.detailTitle}>
                          Since:
                        </Typography>
                        <Typography
                          color="text.primary"
                          sx={{ ...style.detailText, marginRight: "12px" }}
                        >
                          {details.customer_details?.since}
                        </Typography>
                      </Box>
                    )}
                    {details.customer_details?.type && (
                      <Box sx={style.flexRow}>
                        <Typography color="text.primary" sx={style.detailTitle}>
                          Type:
                        </Typography>
                        <Typography color="text.primary" sx={style.detailText}>
                          {details.customer_details?.type}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}

              <Box sx={style.tableContainer}>
                <Typography
                  color="text.primary"
                  sx={{ ...style.heading, ...style.mb10 }}
                >
                  Projects
                </Typography>
                {showLoader.projects && (
                  <Box sx={style.circularLoaderBox}>
                    <CircularProgress
                      size={20}
                      sx={style.circularLoaderBox.loader}
                    />
                  </Box>
                )}
                {projects && projects.length > 0
                  ? projects.map((row, index) => (
                      <Box
                        key={index}
                        sx={[
                          style.tableContainer.row,
                          index + 1 !== projects.length && {
                            borderBottom: "1px solid #ECEFF3",
                          },
                        ]}
                      >
                        <Typography
                          color="text.primary"
                          sx={{ ...style.detailText, flex: 0.7 }}
                        >
                          {row.name}
                        </Typography>
                        <Tooltip
                          title={row.project_managers.join(", ")}
                          placement="top-end"
                          arrow
                        >
                          <Typography
                            color="text.primary"
                            sx={{
                              ...style.detailTitle,
                              ...style.flex1,
                              ...style.managerName,
                            }}
                            textAlign="center"
                          >
                            {row.project_managers.join(", ")}
                          </Typography>
                        </Tooltip>
                      </Box>
                    ))
                  : !showLoader.projects &&
                    !details.projects_count && (
                      <Typography
                        color="text.primary"
                        sx={style.detailText}
                        textAlign={"center"}
                      >
                        No projects available
                      </Typography>
                    )}
                <Box sx={{ mt: "8px" }}>
                  {details.projects_count &&
                  details.projects_count > defaultRowCount.project ? (
                    <Pagination
                      count={Math.ceil(
                        details.projects_count / defaultRowCount.project
                      )}
                      page={currentPage.project}
                      onChange={(event, newPage) =>
                        handlePageChange(newPage, paginationNames.project)
                      }
                      size="small"
                      variant="outlined"
                      shape="rounded"
                      sx={{
                        "& .MuiPaginationItem-root": {
                          color: "gray",
                          "&.Mui-selected": {
                            backgroundColor: "#4E4E4E",
                            color: "white",
                          },
                        },
                        "& .MuiPaginationItem-icon": {
                          color: "#E6292E",
                        },
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>

              <OverlayTableSection
                count={getPaginationCount(
                  invoiceDetailsData.count,
                  defaultRowCount.invoice_details
                )}
                currentPage={currentPage.invoice}
                header={tableData.invoice}
                data={convertedDetails.invoice}
                showLoader={showLoader.invoice}
                showDetails={isShowDetails.invoice}
                totalAmount={checkNullValue(
                  details.revenue?.amount
                ).toLocaleString("en-IN")}
                handlePageChange={(event, newPage) =>
                  handlePageChange(newPage, paginationNames.invoice)
                }
                disableDetails={
                  checkNullValue(details.revenue?.amount) === "NA"
                }
                toggleIsShowDetails={() =>
                  setIsShowDetails({
                    invoice: !isShowDetails.invoice,
                  })
                }
              />

              <OverlayTableSection
                count={getPaginationCount(
                  salaryDetails.count,
                  defaultRowCount.salary_expense
                )}
                currentPage={currentPage.salary_expense}
                header={tableData.salaryExpense}
                data={convertedDetails.salary_expense}
                showLoader={showLoader.salary_expense}
                showDetails={isShowDetails.salary_expense}
                totalAmount={checkNullValue(
                  details.salary_expense?.amount
                ).toLocaleString("en-IN")}
                handlePageChange={(event, newPage) =>
                  handlePageChange(newPage, paginationNames.salaryExpense)
                }
                disableDetails={
                  checkNullValue(details.salary_expense?.amount) === "NA"
                }
                toggleIsShowDetails={() =>
                  setIsShowDetails({
                    salary_expense: !isShowDetails.salary_expense,
                  })
                }
              />

              <OverlayTableSection
                count={getPaginationCount(
                  otherExpenseDetails.count,
                  defaultRowCount.other_expense
                )}
                currentPage={currentPage.other_expense}
                header={tableData.otherExpense}
                data={convertedDetails.other_expense}
                showLoader={showLoader.other_expense}
                showDetails={isShowDetails.other_expense}
                totalAmount={checkNullValue(
                  details.other_expense?.amount
                ).toLocaleString("en-IN")}
                handlePageChange={(event, newPage) =>
                  handlePageChange(newPage, paginationNames.otherExpense)
                }
                disableDetails={
                  checkNullValue(details.other_expense?.amount) === "NA"
                }
                toggleIsShowDetails={() =>
                  setIsShowDetails({
                    other_expense: !isShowDetails.other_expense,
                  })
                }
              />
              <Box sx={{ marginTop: "auto", paddingBottom: "8px" }}>
                <Typography
                  color="text.primary"
                  sx={{ ...style.heading, ...style.mb10 }}
                >
                  Gross Profit
                </Typography>
                <Box
                  sx={{
                    ...style.tableContainer.header,
                    textAlign: "center",
                  }}
                >
                  <Typography
                    color="text.primary"
                    sx={{
                      ...style.detailTitle,
                      paddingTop: "8px",
                      paddingBottom: "4px",
                      marginRight: "0px",
                    }}
                  >
                    {details.gross_profit?.amount
                      ? "Total Gross Profit"
                      : "No Total Gross Profit Available"}
                  </Typography>
                  <Typography
                    color="text.primary"
                    sx={{
                      ...style.heading,
                      fontSize: "22px",
                      paddingBottom: "8px",
                    }}
                  >
                    {details.gross_profit?.amount?.toLocaleString("en-IN")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Slide>
      </Modal>
    );
  }
);

export default OverlayComponent;
